import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {CognitoContextProvider} from './contexts/CognitoContext';
import {OverlayContextProvider} from './contexts/OverlayContext';
import {SessionContextProvider} from './contexts/SessionContext';
import App from './App';

import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const root = ReactDOM.createRoot(document.getElementById('root'));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
root.render(
  <React.StrictMode>
    <CognitoContextProvider>
      <OverlayContextProvider>
        <SessionContextProvider>
          <Elements stripe={stripePromise}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Elements>
        </SessionContextProvider>
      </OverlayContextProvider>
    </CognitoContextProvider>
  </React.StrictMode>
);
