import React, {useState, useEffect, useCallback, useContext}  from 'react';
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom';
import {CognitoContext} from '../../contexts/CognitoContext';
import {SessionContext} from '../../contexts/SessionContext';
import styled from 'styled-components';

import {CHETWODEBLUE, BLACKPEARL, OzLogo, OzTag, OzProjectTag, OzSelect} from '@ozwol/webui';

import ProjectsService from './../../services/ProjectsService';
import AccountService from './../../services/AccountService';

import {checkPermission} from './../../helpers';



const Wrapper = styled.div`
`;
const Top = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const Content = styled.div`
  padding: 20px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  color: ${props => props.isActive ? CHETWODEBLUE : BLACKPEARL};
  font-weight: ${props => props.isActive ? "600" : "400"};

  &:hover{
    color: ${CHETWODEBLUE};

  }
`;
const Icon = styled.div`
  display: inline-grid;
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;

  & *{
    font-size: 20px;
  }
`;
const Text = styled.div`
  font-size: bold;
  font-size: 14px;
  flex-grow: 1;
  flex-shrink: 1;
`;
const Notify = styled.div`
  flex-grow: 0;
  flex-shrink: 0;  
  display: flex;
  justify-content: space-around;
  align-items: center;
`;


const Sidebar = ({section}) => {
  const navigate = useNavigate();
  const { projectUuid } = useParams();

  // const [currentProject, setCurrentProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [filterSearchProjects, setFilterSearchProjects] = useState([]);
  const [search, setSearch] = useState(null);
  const [period, setPeriod] = useState(null);
  const {cognito} = useContext(CognitoContext);
  const {currentProject, setCurrentProject} = useContext(SessionContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if(checkPermission("project", "list")){
      ProjectsService.list().then((response) => {
        if(response && response.result){
          setProjects(response.result);
          setFilterSearchProjects(response.result);
        }
      });
    }
    if(cognito && cognito.groups && !cognito.groups.includes("OzwolCoworker")){ 
      AccountService.getUser().then((res) => {
        setUser(res);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [cognito])

  useEffect(() => {
    if(checkPermission("project", "list")){
      ProjectsService.list({
        "search": search && search.length > 2 ? search : "",
      }).then((response) => {
        if(response && response.result){
          setFilterSearchProjects(response.result);
        }
      });
    }
  }, [search]);
  
  useEffect(() => {
    if(checkPermission("period", "read")){
      AccountService.getPeriod({
        "includeRecharges": checkPermission("recharge", "list")
      }).then((response1) => {
        setPeriod(response1);
      }).catch((error) => {
        setPeriod(null);
      });
    }
  }, [section]);

  useEffect(() => {
    if(projectUuid){
      setCurrentProject(projectUuid);
    }    
  }, [projectUuid, setCurrentProject]);

  const changeProject = useCallback((val, area) => {
    if(val){
      setCurrentProject(val);
      navigate("/"+area+"/"+val+"/"+(area === "project" ? "dashboard" : "hotfolders"));
    }
  }, [navigate, setCurrentProject]);

  return (
    <Wrapper>
      <Top>
        <Link to="/overall/overview">
          <OzLogo height="18px"/>
        </Link>
      </Top>
      <Content>
        {section === "overall" ? 
          <>
            <NavLink to={"/overall/overview"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">mountain_flag</span>
                    : null}
                  </Icon>
                  <Text>
                    Overview
                  </Text>
                </Item>
              )}
            </NavLink>
            {checkPermission("recharge", "list") ? 
              <>
                {period && period.currentPlan && period.currentPlan.planType === "PAID" ? 
                  <NavLink to={"/overall/recharges"} exact="true">
                    {({ isActive }) => (
                      <Item isActive={isActive}>
                        <Icon>
                          {isActive ? 
                            <span className="material-symbols-outlined">nearby</span>
                          : null}
                        </Icon>
                        <Text>
                          Recharges
                        </Text>
                        {period.recharges.length > 0 ? 
                          <Notify>
                            <OzTag variant={"white"} size={"small"}>
                              {period.recharges.length}
                            </OzTag>
                          </Notify>
                        : null}
                      </Item>
                    )}
                  </NavLink>
                : null }
              </>
            : null }
            {(cognito && cognito.groups && cognito.groups.includes("OzwolCoworker")) || (user && user.currentPlan && user.currentPlan.apiAllowed) ? 
              <>
                {checkPermission("apikey", "list") ? 
                  <NavLink to={"/overall/apikeys"} exact="true">
                    {({ isActive }) => (
                      <Item isActive={isActive}>
                        <Icon>
                          {isActive ? 
                            <span className="material-symbols-outlined">key</span>
                          : null}
                        </Icon>
                        <Text>
                          API Keys
                        </Text>
                      </Item>
                    )}
                  </NavLink>
                : null}
              </>
            : null}
            {checkPermission("destination", "list") ? 
              <NavLink to={"/overall/destinations"} exact="true">
                {({ isActive }) => (
                  <Item isActive={isActive}>
                    <Icon>
                      {isActive ? 
                        <span className="material-symbols-outlined">near_me</span>
                      : null}
                    </Icon>
                    <Text>
                      Destinations
                    </Text>
                  </Item>
                )}
              </NavLink>
            : null }
            {checkPermission("log", "list") ? 
              <NavLink to={"/overall/logs"} exact="true">
                {({ isActive }) => (
                  <Item isActive={isActive}>
                    <Icon>
                      {isActive ? 
                        <span className="material-symbols-outlined">checklist_rtl</span>
                      : null}
                    </Icon>
                    <Text>
                      Logs
                    </Text>
                  </Item>
                )}
              </NavLink>
            : null }
          </>
        : section === "project" ? 
          <>
            {checkPermission("project", "list") && projects.length > 0 ?
              <>
                <OzSelect
                  variant={"default"}
                  width={"215px"}
                  height={"35px"}
                  maxHeight={"300px"}
                  selected={currentProject}
                  placeholder={"Select project"}
                  values={filterSearchProjects.map((item) => ({
                    "name": <OzProjectTag
                        name={item.name}
                        color={item.color}
                        variant={"chetwodeblue"}
                        direction={"horizontal"}
                      />,
                    "value": item.uuid
                  }))}
                  // disabled={projects.length < 2}
                  searchable={projects.length > 8}
                  onChange={(val) => {changeProject(val, "project")}}
                  onSearch={(val) => {setSearch(val)}}
                />
                <br/>
              </>
            : null }

            {checkPermission("project", "read") && currentProject ?
              <>
                <NavLink to={"/project/"+currentProject+"/dashboard"} exact="true">
                  {({ isActive }) => (
                    <Item isActive={isActive}>
                      <Icon>
                        {isActive ? 
                          <span className="material-symbols-outlined">data_usage</span>
                        : null}
                      </Icon>
                      <Text>
                        Dashboard
                      </Text>
                    </Item>
                  )}
                </NavLink>
                {checkPermission("log", "list") ? 
                  <NavLink to={"/project/"+currentProject+"/logs"} exact="true">
                    {({ isActive }) => (
                      <Item isActive={isActive}>
                        <Icon>
                          {isActive ? 
                            <span className="material-symbols-outlined">checklist_rtl</span>
                          : null}
                        </Icon>
                        <Text>
                          Logs
                        </Text>
                      </Item>
                    )}
                  </NavLink>
                : null}
                <NavLink to={"/project/"+currentProject+"/settings"}>
                  {({ isActive }) => (
                    <Item isActive={isActive}>
                      <Icon>
                        {isActive ? 
                          <span className="material-symbols-outlined">work_update</span>
                        : null}
                      </Icon>
                      <Text>
                        Project settings
                      </Text>
                    </Item>
                  )}
                </NavLink>
                {checkPermission("projectCredentials", "read") ? 
                  <NavLink to={"/project/"+currentProject+"/access"}>
                    {({ isActive }) => (
                      <Item isActive={isActive}>
                        <Icon>
                          {isActive ? 
                            <span className="material-symbols-outlined">hub</span>
                          : null}
                        </Icon>
                        <Text>
                          Workspace access
                        </Text>
                      </Item>
                    )}
                  </NavLink>
                : null}
                {/* <NavLink to={"/project/"+currentProject+"/hotfolders"}>
                  {({ isActive }) => (
                    <Item isActive={isActive}>
                      <Icon>
                        {isActive ? 
                          <span className="material-symbols-outlined">folder</span>
                        : null}
                      </Icon>
                      <Text>
                      Hotfolders
                      </Text>
                    </Item>
                  )}
                </NavLink> */}

              </>
            : null }
          </>
        : section === "account" ? 
        <>
          <NavLink to={"/account/profile"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                <Icon>
                  {isActive ? 
                    <span className="material-symbols-outlined">person</span>
                  : null}
                </Icon>
                <Text>
                  Profile
                </Text>
              </Item>
            )}
          </NavLink>
          <NavLink to={"/account/security"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                <Icon>
                  {isActive ? 
                    <span className="material-symbols-outlined">security</span>
                  : null}
                </Icon>
                <Text>
                  Security
                </Text>
              </Item>
            )}
          </NavLink>
          {checkPermission("subBillingInfo", "read") ? 
            <NavLink to={"/account/billinginfo"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">receipt_long</span>
                    : null}
                  </Icon>
                  <Text>
                    Billing info
                  </Text>
                </Item>
              )}
            </NavLink>
          : null}
          {checkPermission("payment", "list") && period && period.currentPlan.planType === "PAID" ? 
            <NavLink to={"/account/paymentmethods"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">account_balance</span>
                    : null}
                  </Icon>
                  <Text>
                    Payment methods
                  </Text>
                </Item>
              )}
            </NavLink>
          : null}
          {checkPermission("period", "list") ? 
            <NavLink to={"/account/periodhistory"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">history</span>
                    : null}
                  </Icon>
                  <Text>
                    Period history
                  </Text>
                </Item>
              )}
            </NavLink>
          : null }
          {checkPermission("subNotifications", "read") ? 
            <NavLink to={"/account/notifications"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">notifications</span>
                    : null}
                  </Icon>
                  <Text>
                    Notifications
                  </Text>
                </Item>
              )}
            </NavLink>
          : null }
          {checkPermission("subPlan", "list") ? 
            <NavLink to={"/account/plans"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">amp_stories</span>
                    : null}
                  </Icon>
                  <Text>
                    Plans
                  </Text>
                </Item>
              )}
            </NavLink>
          : null }
        </>
      : section === "team" ? 
        <>
          {checkPermission("coworker", "list") && ((cognito && cognito.groups && cognito.groups.includes("OzwolCoworker")) || (user && user.currentPlan.teamAllowed)) ? 
            <>
              <NavLink to={"/team/members"} exact="true">
                {({ isActive }) => (
                  <Item isActive={isActive}>
                    <Icon>
                      {isActive ? 
                        <span className="material-symbols-outlined">group</span>
                      : null}
                    </Icon>
                    <Text>
                      Team members
                    </Text>
                  </Item>
                )}
              </NavLink>
              <NavLink to={"/team/permissions"} exact="true">
                {({ isActive }) => (
                  <Item isActive={isActive}>
                    <Icon>
                      {isActive ? 
                        <span className="material-symbols-outlined">passkey</span>
                      : null}
                    </Icon>
                    <Text>
                      Permission detail
                    </Text>
                  </Item>
                )}
              </NavLink>
            </>
          : null }
        </>
      : section === "workspace" ? 
        <>
          {checkPermission("project", "list") && projects.length > 0 ?
            <>
              <OzSelect
                variant={"default"}
                width={"215px"}
                height={"35px"}
                maxHeight={"300px"}
                selected={currentProject}
                placeholder={"Select project"}
                values={filterSearchProjects.map((item) => ({
                  "name": <OzProjectTag
                      name={item.name}
                      color={item.color}
                      variant={"chetwodeblue"}
                      direction={"horizontal"}
                    />,
                  "value": item.uuid
                }))}
                // disabled={projects.length < 2}
                searchable={projects.length > 8}
                onChange={(val) => {changeProject(val, "workspace")}}
                onSearch={(val) => {setSearch(val)}}
              />
              <br/>
            </>
          : null }

          {checkPermission("project", "read") && currentProject ?
            <>
              <NavLink to={"/workspace/"+currentProject+"/hotfolders"} exact="true">
                {({ isActive }) => (
                  <Item isActive={isActive}>
                    <Icon>
                      {isActive ? 
                        <span className="material-symbols-outlined">perm_media</span>
                      : null}
                    </Icon>
                    <Text>
                      Hotfolders
                    </Text>
                  </Item>
                )}
              </NavLink>

            </>
          : null }

          {checkPermission("project", "read") && currentProject ?
            <>
              <NavLink to={"/workspace/"+currentProject+"/downloads"} exact="true">
                {({ isActive }) => (
                  <Item isActive={isActive}>
                    <Icon>
                      {isActive ? 
                        <span className="material-symbols-outlined">download</span>
                      : null}
                    </Icon>
                    <Text>
                      Downloads
                    </Text>
                  </Item>
                )}
              </NavLink>

            </>
          : null }
        </>
      : section === "insider" ? 
          <>
            <NavLink to={"/insider/overview"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">mountain_flag</span>
                    : null}
                  </Icon>
                  <Text>
                    Overview
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/trends"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">price_change</span>
                    : null}
                  </Icon>
                  <Text>
                    Trends
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/renewals"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">price_change</span>
                    : null}
                  </Icon>
                  <Text>
                    Renewals
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/performances"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">price_change</span>
                    : null}
                  </Icon>
                  <Text>
                    Performances
                  </Text>
                </Item>
              )}
            </NavLink>
            <br/>
            <NavLink to={"/insider/logs"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">checklist_rtl</span>
                    : null}
                  </Icon>
                  <Text>
                    Logs
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/scripts"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">hotel_class</span>
                    : null}
                  </Icon>
                  <Text>
                    Scripts
                  </Text>
                </Item>
              )}
            </NavLink>
            <br/>
            <NavLink to={"/insider/users"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">person</span>
                    : null}
                  </Icon>
                  <Text>
                    Users
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/coworkers"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">group</span>
                    : null}
                  </Icon>
                  <Text>
                    Coworkers
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/plans"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">amp_stories</span>
                    : null}
                  </Icon>
                  <Text>
                    Plans
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/projects"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">business_center</span>
                    : null}
                  </Icon>
                  <Text>
                    Projects
                  </Text>
                </Item>
              )}
            </NavLink>
            <NavLink to={"/insider/hotfolders"} exact="true">
              {({ isActive }) => (
                <Item isActive={isActive}>
                  <Icon>
                    {isActive ? 
                      <span className="material-symbols-outlined">folder</span>
                    : null}
                  </Icon>
                  <Text>
                    Hotfolders
                  </Text>
                </Item>
              )}
            </NavLink>
          </>
        : null }
      </Content>
    </Wrapper>
  )
}

export default Sidebar;
