import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import { Line } from 'react-chartjs-2';

import {OzContainer, OzRow, OzCol, OzCard, OzButton, CHETWODEBLUE, SPANISHGREY, SILVERTREE, BITTERSWEET} from '@ozwol/webui';


import UsersService from '../../services/UsersService';
import UsagesService from '../../services/UsagesService';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import ChartWrapper from '../../components/ChartWrapper';
import Filters from './../../components/Filters';

import {formatError, getFormattedDate, getArrayFromInterval} from './../../helpers';
import {chartJsLineOptions} from './../../config';



const USAGES_INDEX_COLUMN = 0;
const USAGES_CREDITS_COLUMN = 2;


const CardWrapper = styled.div`
  & > *{
    padding: 10px;
  }
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`;
const CardHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  
`;
const CardHeaderRight = styled.div`  
  display: flex;
  align-items: baseline;
`;


const SuperNumber = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
  color: ${props => props.isPositive ? SILVERTREE : BITTERSWEET};
`;
const ButtonIcon = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px;
  & > span{
    font-size: 20px;
  }
`;

const InsiderPerformancesSingle = ({ownerUuid, company, period, filter}) => {
  let navigate = useNavigate();
  const [usages, setUsages] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  
  useEffect(() => {
    if(period){
      UsagesService.getCumulativeUsagesByOwner({
        ownerUuid: period.ownerUuid,
        grain: "d",
        start: period.startDate,
        end: period.endDate
      }).then((response) => {   
        let periodInterval = getArrayFromInterval(period.startDate, period.endDate);
        let deltaIndex = periodInterval.length - 1;
        if(new Date(period.endDate) > new Date()){
          deltaIndex = getArrayFromInterval(period.startDate, new Date()).length - 1;
        }

        let deltaExpected, deltaEffective, delta;
        if(response.dataset.filter(set => set[USAGES_INDEX_COLUMN] === periodInterval[deltaIndex]).length > 0){
          deltaExpected = deltaIndex * (period.currentPlan.credits / periodInterval.length);  
          if(deltaExpected !== 0){
            deltaEffective = response.dataset.filter(set => set[USAGES_INDEX_COLUMN] === periodInterval[deltaIndex])[0][USAGES_CREDITS_COLUMN];
            delta = Math.round(  ((deltaEffective - deltaExpected) / deltaExpected)*100 );
          }else{
            delta = null;
          }
        }else{
          delta = null;
        }

        setUsages({
          labels: periodInterval.map(day => getFormattedDate(day, false)),
          datasets: [
            {
              data: periodInterval.map(day => 
                response.dataset.filter(set => set[USAGES_INDEX_COLUMN] === day).length > 0 ? 
                  response.dataset.filter(set => set[USAGES_INDEX_COLUMN] === day)[0][USAGES_CREDITS_COLUMN]
                : null
              ),
              borderColor: CHETWODEBLUE,
              tension: 0.1,
              spanGaps: false
            },
            {
              data: periodInterval.map((v,i) => parseInt((i+1)* (period.currentPlan.credits / periodInterval.length))),
              borderColor: SPANISHGREY,
              borderWidth: 1,
              tension: 0.1,
              pointStyle: false,
              segment: {
                borderDash: [10, 3],
              }
            }
          ],
          delta: delta
        });

      }).catch((error) => {
        setFetchError(formatError(error));
      }); 
    }
  }, [period]);

  if(!filter || (filter && usages && usages.delta && (
    (usages.delta > 0 && filter > 0) ||
    (usages.delta < 0 && filter < 0)
  ))){
    return (
      <OzCol widthmd="6" order={usages && usages.delta ? 100-usages.delta : 999}>
        <HealthyWrapper error={fetchError} loading={!usages}>
          <CardWrapper>
            <OzCard height="100%">       
              <center>   
                <CardHeader>
                  <CardHeaderLeft>                
                    <b>{company}</b> &nbsp;
                    <OzButton
                        size="small" 
                        noMinWidth 
                        variant="push-black"
                        onClick={() => {
                            localStorage.setItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), ownerUuid);
                            navigate("/overall/overview");
                        }}
                    ><ButtonIcon><span className="material-symbols-outlined">prompt_suggestion</span></ButtonIcon></OzButton>
                  </CardHeaderLeft>
                  <CardHeaderRight>
                    {usages && usages.delta ?
                      <>
                        Credits <SuperNumber isPositive={usages.delta > 0}>{usages.delta > 0 ? "+":null}{usages.delta}%</SuperNumber>
                      </>
                    : null}
                  </CardHeaderRight>
                </CardHeader>
                <ChartWrapper>
                  {usages ? 
                    <Line options={chartJsLineOptions} data={usages} />
                  : null}
                </ChartWrapper>    
              </center>
            </OzCard>
          </CardWrapper>
        </HealthyWrapper>
      </OzCol>
    )
  }else{
    return null;
  }

}



const InsiderPerformances = ({refScrollerPage}) => {
  const [users, setUsers] = useState([]);  
  const [periods, setPeriods] = useState([]);
  // const [usages, setUsages] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  
    const DEFAULT_FILTERS = {
      "delta": null,
    };
  const [filters, setFilters] = useState(
    localStorage.getItem("ozwolConsoleFilterInsiderPerformances"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
      JSON.parse(localStorage.getItem("ozwolConsoleFilterInsiderPerformances"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    : 
      DEFAULT_FILTERS
    );
  
  useEffect(() => {
    UsersService.adminList().then((response) => {
      setUsers(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });  
    UsersService.adminListPeriods().then((response) => {
      setPeriods(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    
  }, []);
  
    const changeFilters = useCallback((name, value) => {
      let tempFilters = {...filters};
      tempFilters[name] = value;
      setFilters(tempFilters);
    }, [filters]);

  return (
    <OzContainer size="extra">
      <Meta title={"Performances"} />
      <PageHeader
        breadcrumbsIcon="price_change"
        breadcrumbsText={["Performances"]}
        refScrollerPage={refScrollerPage}
      />
      <HealthyWrapper error={fetchError} loading={!users}>
        
        {/* <OzRow>
          <OzCol widthmd={2}>
            <OzButton
              width="100%"
              variant={"push-black"}
              pushed={interval === INT_CUSTOM}
              onClick={() => setInterval(INT_CUSTOM)}
            >
              XXX
            </OzButton>
          </OzCol>
          <OzCol widthmd={2}>
            <OzButton
              width="100%"
              variant={"push-black"}
              pushed={interval === INT_YEAR}
              onClick={() => setInterval(INT_YEAR)}
            >
              Last year
            </OzButton>
          </OzCol>
          <OzCol widthmd={2}>
            <OzButton
              width="100%"
              variant={"push-black"}
              pushed={interval === INT_SEMESTER}
              onClick={() => setInterval(INT_SEMESTER)}
            >
              Last semester
            </OzButton>
          </OzCol>
          <OzCol widthmd={2}>
            <OzButton
              width="100%"
              variant={"push-black"}
              pushed={interval === INT_MONTH}
              onClick={() => setInterval(INT_MONTH)}
            >
              Last month
            </OzButton>
          </OzCol>
          <OzCol widthmd={2}>
            <OzButton
              width="100%"
              variant={"push-black"}
              pushed={interval === INT_WEEK}
              onClick={() => setInterval(INT_WEEK)}
            >
              Last week
            </OzButton>
          </OzCol>
          <OzCol widthmd={2}>
            <OzButton
              width="100%"
              variant={"push-black"}
              pushed={interval === INT_TODAY}
              onClick={() => setInterval(INT_TODAY)}
            >
              Today
            </OzButton>
          </OzCol>
        </OzRow> */}
        <OzRow>
          <OzCol>
            <Filters
              onChange={(...args) => changeFilters(...args)}
              fields={
                [
                  {
                    "label": "Delta",
                    "name": "delta",
                    "size": 2,
                    "type": "select",
                    "placeholder": null,
                    "value": filters.delta,
                    "options": [
                      {
                        "name": "All logs",
                        "value": null
                      },{
                        "name": <i>Above average</i>,
                        "value": 1,
                      },{
                        "name": <i>Below average</i>,
                        "value": -1,
                      }
                    ]
                  },{
                    "size": 2,
                    "type": "clear",
                    "callback": () => {
                      setFilters(DEFAULT_FILTERS);
                      // localStorage.removeItem("ozwolConsoleFilterInsiderLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                    }
                  }
                ]
              }
            />
            <hr/>
          </OzCol>
        </OzRow>
        <br/>
        {periods.length > 0 ?
          <OzRow>
            {periods.map((period) => 
              period.endDate ?                 
                <InsiderPerformancesSingle 
                  period={period}
                  key={period.ownerUuid}
                  ownerUuid={period.ownerUuid}
                  filter={filters.delta}
                  company={users.filter((user) => user.uuid === period.ownerUuid).length > 0 ? users.filter((user) => user.uuid === period.ownerUuid)[0].company : "-"}
                />
              : null
            )}
          </OzRow>
        : null }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default InsiderPerformances;
