import {useContext, useState, useCallback, useEffect} from 'react';
import {Routes, Route, useNavigate, Navigate, useParams} from 'react-router-dom';
import {CognitoContext} from './contexts/CognitoContext';
import {SessionContext} from './contexts/SessionContext';
import {OzBody} from '@ozwol/webui';
import './App.css';
// import {BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, LAYOUT_LG, LAYOUT_MD, LAYOUT_SM, LAYOUT_XS } from './config';
import {adminControl, checkPermission} from './helpers';

// import AccountService from './services/AccountService';

import Layout from "./components/Layout";
// import MobileBlocker from "./components/MobileBlocker";

//Overall
import Overview from "./pages/overall/Overview";
import Recharges from "./pages/overall/Recharges";
import ApiKeys from "./pages/overall/ApiKeys";
import Destinations from "./pages/overall/Destinations";
import Logs from "./pages/overall/Logs";
import Changelog from "./pages/overall/Changelog";

//Project
// import NoProject from "./pages/project/NoProject";
import Dashboard from "./pages/project/Dashboard";
import ProjectLogs from "./pages/project/Logs";
import Settings from "./pages/project/Settings";
import Access from "./pages/project/Access";
// import Hotfolders from "./pages/project/Hotfolders";
// import HotfolderScript from "./pages/project/HotfolderScript";
// import HotfolderDetailScript from "./pages/project/HotfolderDetailScript";

//Account
import Profile from "./pages/account/Profile";
import Security from "./pages/account/Security";
import PeriodHistory from "./pages/account/PeriodHistory";
import BillingInfo from "./pages/account/BillingInfo";
import PaymentMethods from "./pages/account/PaymentMethods";
import Plans from "./pages/account/Plans";
import Notifications from "./pages/account/Notifications";

//Team
import Members from "./pages/team/Members";
import Permissions from "./pages/team/Permissions";

//Workspace
import WorkspaceHotfolders from "./pages/workspace/WorkspaceHotfolders";
import WorkspaceDownloads from "./pages/workspace/WorkspaceDownloads";

//Insider
import InsiderOverview from "./pages/insider/InsiderOverview";
import InsiderTrends from "./pages/insider/InsiderTrends";
import InsiderRenewals from "./pages/insider/InsiderRenewals";
import InsiderPerformances from "./pages/insider/InsiderPerformances";
import InsiderLogs from "./pages/insider/InsiderLogs";
import InsiderScripts from "./pages/insider/InsiderScripts";
import InsiderUsersList from "./pages/insider/InsiderUsersList";
import InsiderUsersSingle from "./pages/insider/InsiderUsersSingle";
import InsiderCoworkersList from "./pages/insider/InsiderCoworkersList";
import InsiderPlansList from "./pages/insider/InsiderPlansList";
import InsiderPlansSingle from "./pages/insider/InsiderPlansSingle";
import InsiderProjectsList from "./pages/insider/InsiderProjectsList";
import InsiderProjectsSingle from "./pages/insider/InsiderProjectsSingle";
import InsiderHotfoldersList from "./pages/insider/InsiderHotfoldersList";
import InsiderHotfoldersSingle from "./pages/insider/InsiderHotfoldersSingle";
import InsiderHotfoldersActionsSingleDetail from "./pages/insider/InsiderHotfoldersActionsSingleDetail";


//Authentication
import Auth from "./pages/auth/Auth";

//Others
import Join from "./pages/Join";

//404
import NotFound from "./pages/NotFound";
import NotAuthorized from "./pages/NotAuthorized";


import ProjectsService from './services/ProjectsService';
import { FORCE_LOGOUT } from './config';

const RequireAuth = ({resource, verb, children}: {children: JSX.Element}) => {
  const {cognito} = useContext(CognitoContext);
  // console.log(cognito);
  // console.log(resource);
  const navigate = useNavigate();
  if (!cognito) {
    navigate("/auth/login");
  }else if(resource && verb && !checkPermission(resource, verb)){
    navigate("/not-authorized");
  }else{
    return (children);
  }
}
const RequireAdminAuth = ({children}: {children: JSX.Element}) => {
  const {cognito} = useContext(CognitoContext);
  const navigate = useNavigate();
  if(!adminControl(cognito)){
    navigate("/auth/login");
  }else{
    localStorage.removeItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
    return (children);
  }
}
const NavigateToProjectDashboard = ({children}: {children: JSX.Element}) => {
  const {projectUuid} = useParams();
  const navigate = useNavigate();
  navigate("/project/"+projectUuid+"/dashboard");
}

const NavigateToWorkspaceHotfolders = ({children}: {children: JSX.Element}) => {
  const {projectUuid} = useParams();
  const navigate = useNavigate();
  navigate("/workspace/"+projectUuid+"/hotfolders");
}

const CheckImpersonification = ({children}: {children: JSX.Element}) => {
  let { impersonification } = useParams();
  const {cognito} = useContext(CognitoContext);
  const navigate = useNavigate();
  if (!adminControl(cognito)) {
    navigate("/auth/login");
  }else{
    localStorage.setItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), impersonification);
    return (children);
  }
}

const App = () => {
  const [resizeListener, setResizeListener] = useState(false);
  // const [layout, setLayout] = useState(null);
  const {cognito, setCognito} = useContext(CognitoContext);
  const {currentProject} = useContext(SessionContext);
  // const [user, setUser] = useState(null);
  const [defaultProjectUuid, setDefaultProjectUuid] = useState(null);
  const navigate = useNavigate();

  const handleResize = useCallback(() => {
    // setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
  }, []);

  useEffect(() => {
    if(!resizeListener){
      setResizeListener(true);
      window.addEventListener("resize", handleResize, false);
      handleResize();
    }
  }, [handleResize, resizeListener]);

  useEffect(() => {
    let storagedCognito = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
    if(storagedCognito && (typeof storagedCognito.forceLogout === 'undefined' || storagedCognito.forceLogout !== FORCE_LOGOUT)){
      navigate("/auth/login");
    }else{
      if (!cognito && storagedCognito){
        setCognito(storagedCognito);
      }
    }
  }, [cognito, setCognito, navigate]);

  useEffect(() => {
    if(cognito){
      ProjectsService.list().then((response) => {
        if(response.result.length > 0){
          if(currentProject){
            if(response.result.map(item => item.uuid).includes(currentProject)){
              setDefaultProjectUuid(currentProject);
            }else{
              setDefaultProjectUuid(response.result[0].uuid);
            }
          }else{
            setDefaultProjectUuid(response.result[0].uuid);
          }         
        }else{
          setDefaultProjectUuid(null);
        }
      }).catch((error) => {
        setDefaultProjectUuid(null);
      });
      // if(cognito.groups && !cognito.groups.includes("OzwolCoworker")){ 
      //   AccountService.getUser().then((res) => {
      //     setUser(res);
      //   }).catch((error) => {
      //     console.log(error);
      //   });
      // }
    }
  }, [cognito, currentProject]);

  // useEffect(() => {
  //   console.log("asd");
  // }, []);

  

  // if(layout === LAYOUT_XS || layout === LAYOUT_SM ){
  //   return(
  //     <>
  //       <MobileBlocker />
  //     </>
  //   );
  // }else{
    return (
      <OzBody area="console">
        <Routes>

          {/*Core*/}
          <Route exact path='/impersonification/:impersonification' element={<CheckImpersonification><Navigate to="/" /></CheckImpersonification>}/>
          <Route exact path='/refresh' element={<Navigate to="/" />}/>
          <Route exact path="/" element={
            <RequireAuth>
              <Navigate to="/overall/overview" />
            </RequireAuth>
          } />
          <Route exact path='/joinTeam' element={<Join />}/>
          
          {/*Overview*/}
          <Route exact path="/overall" element={
            <RequireAuth>
              <Navigate to="/overall/overview" />
            </RequireAuth>
          } />
          <Route exact path="/overall/overview" element={
            <RequireAuth>
              <Layout section="overall"><Overview /></Layout>
            </RequireAuth>
          } />
          <Route exact path="/overall/recharges" element={
            <RequireAuth resource="recharge" verb="list">
              <Layout section="overall"><Recharges /></Layout>
            </RequireAuth>
          } />
          
          {/* {(cognito && cognito.groups && cognito.groups.includes("OzwolCoworker")) || (user && user.currentPlan && user.currentPlan.apiAllowed) ?  */}
            <Route exact path="/overall/apikeys" element={
              <RequireAuth resource="apikey" verb="list">
                <Layout section="overall"><ApiKeys /></Layout>
              </RequireAuth>
            } />
          {/* :
            <Route exact path='/overall/apikeys' element={<Navigate to="/not-authorized" />}/>
          */}
          
          <Route exact path="/overall/destinations" element={
            <RequireAuth resource="destination" verb="list">
              <Layout section="overall"><Destinations /></Layout>
            </RequireAuth>
          } />        
          <Route exact path='/overall/logs' element={
            <RequireAuth resource="log" verb="list">
              <Layout section="overall"><Logs /></Layout>
            </RequireAuth>
          }/>
          <Route exact path="/overall/changelog" element={
            <RequireAuth>
              <Layout section="overall"><Changelog /></Layout>
            </RequireAuth>
          } />

          {/*Project*/}
          <Route exact path="/project" element={
            <RequireAuth resource="project" verb="read">
              <Navigate to={"/project/"+defaultProjectUuid+"/dashboard"} />
              </RequireAuth>
          } />
          <Route exact path="/project/:projectUuid" element={
            <RequireAuth resource="project" verb="read">
              <NavigateToProjectDashboard />
            </RequireAuth>
          } />
          <Route exact path='/project/:projectUuid/dashboard' element={
            <RequireAuth resource="project" verb="read">
              <Layout section="project"><Dashboard /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/project/:projectUuid/logs' element={
            <RequireAuth resource="project" verb="read">
              <RequireAuth resource="log" verb="list">
                <Layout section="project"><ProjectLogs /></Layout>
              </RequireAuth>
            </RequireAuth>
          }/>
          <Route exact path='/project/:projectUuid/settings' element={
            <RequireAuth resource="project" verb="read">
              <Layout section="project"><Settings /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/project/:projectUuid/access' element={ 
            <RequireAuth resource="project" verb="read">
              <RequireAuth resource="projectCredentials" verb="read">
                <Layout section="project"><Access /></Layout>
              </RequireAuth>
            </RequireAuth>
          }/>
          {/* <Route exact path='/project/:projectUuid/hotfolders' element={
            <RequireAuth resource="project" verb="read">
              <Layout section="project"><Hotfolders /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/project/:projectUuid/hotfolders/:hotfolderUuid/:uuid' element={
            <RequireAuth resource="project" verb="read">
              <Layout section="project" detail={<HotfolderDetailScript />}><HotfolderScript /></Layout>
            </RequireAuth>
          }/> */}
            
          {/*Account*/}
          <Route exact path='/account' element={
            <RequireAuth>
              <Layout section="account"><Navigate to="profile" /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/profile' element={
            <RequireAuth>
              <Layout section="account"><Profile /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/security' element={
            <RequireAuth>
              <Layout section="account"><Security /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/billinginfo' element={
            <RequireAuth resource="subBillingInfo" verb="read">
              <Layout section="account"><BillingInfo /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/paymentmethods' element={
            <RequireAuth resource="payment" verb="list">
              <Layout section="account"><PaymentMethods /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/periodhistory' element={
            <RequireAuth resource="period" verb="list">
              <Layout section="account"><PeriodHistory /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/notifications' element={
            <RequireAuth resource="subNotifications" verb="read">
              <Layout section="account"><Notifications /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/account/plans' element={
            <RequireAuth resource="subPlan" verb="list">
              <Layout section="account"><Plans /></Layout>
            </RequireAuth>
          }/>

          {/*Team*/}
          {/* {((cognito && cognito.groups && cognito.groups.includes("OzwolCoworker")) || (user && user.currentPlan.teamAllowed)) ?  */}
            <>
              <Route exact path='/team' element={
                <RequireAuth resource="coworker" verb="list">
                  <Layout section="team"><Navigate to="members" /></Layout>
                </RequireAuth>
              }/>
              <Route exact path='/team/members' element={
                <RequireAuth resource="coworker" verb="list">
                  <Layout section="team"><Members /></Layout>
                </RequireAuth>
              }/>
              <Route exact path='/team/permissions' element={
                <RequireAuth resource="coworker" verb="list">
                  <Layout section="team"><Permissions /></Layout>
                </RequireAuth>
              }/>
            </>
          {/* :
            <>
              <Route exact path='/team' element={<Navigate to="/not-authorized" />}/>
              <Route exact path='/team/members' element={<Navigate to="/not-authorized" />}/>
              <Route exact path='/team/permissions' element={<Navigate to="/not-authorized" />}/>
            </>
          } */}

          {/*Workspace*/}
          <Route exact path="/workspace" element={
            <RequireAuth resource="project" verb="read">
              <Navigate to={"/workspace/"+defaultProjectUuid+"/hotfolders"} />
              </RequireAuth>
          } />
          <Route exact path="/workspace/:projectUuid" element={
            <RequireAuth resource="workspace" verb="read">
              <NavigateToWorkspaceHotfolders />
            </RequireAuth>
          } />
          <Route exact path='/workspace/:projectUuid/hotfolders' element={
            <RequireAuth resource="project" verb="read">
              <Layout section="workspace"><WorkspaceHotfolders /></Layout>
            </RequireAuth>
          }/>
          <Route exact path='/workspace/:projectUuid/downloads' element={
            <RequireAuth resource="project" verb="list">
              <Layout section="workspace"><WorkspaceDownloads /></Layout>
            </RequireAuth>
          }/>

          {/*Insider*/}
          <Route exact path='/insider' element={<RequireAdminAuth><Navigate to="/insider/overview" /></RequireAdminAuth>}/>
          <Route exact path='/insider/overview' element={<RequireAdminAuth><Layout section="insider"><InsiderOverview /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/trends' element={<RequireAdminAuth><Layout section="insider"><InsiderTrends /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/renewals' element={<RequireAdminAuth><Layout section="insider"><InsiderRenewals /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/performances' element={<RequireAdminAuth><Layout section="insider"><InsiderPerformances /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/logs' element={<RequireAdminAuth><Layout section="insider"><InsiderLogs /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/scripts' element={<RequireAdminAuth><Layout section="insider"><InsiderScripts /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/users' element={<RequireAdminAuth><Layout section="insider"><InsiderUsersList /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/coworkers' element={<RequireAdminAuth><Layout section="insider"><InsiderCoworkersList /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/users/:uuid' element={<RequireAdminAuth><Layout section="insider"><InsiderUsersSingle /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/plans' element={<RequireAdminAuth><Layout section="insider"><InsiderPlansList /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/plans/:uuid' element={<RequireAdminAuth><Layout section="insider"><InsiderPlansSingle /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/projects' element={<RequireAdminAuth><Layout section="insider"><InsiderProjectsList /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/projects/:uuid' element={<RequireAdminAuth><Layout section="insider"><InsiderProjectsSingle /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/hotfolders' element={<RequireAdminAuth><Layout section="insider"><InsiderHotfoldersList /></Layout></RequireAdminAuth>}/>
          <Route exact path='/insider/hotfolders/:hotfolderUuid' element={<RequireAdminAuth><Layout section="insider"><InsiderHotfoldersSingle /></Layout></RequireAdminAuth>}/>
          <Route exact path="/insider/hotfolders/:hotfolderUuid/:actionUuid" element={<RequireAdminAuth><Layout section="insider" detail={<InsiderHotfoldersActionsSingleDetail />}><InsiderHotfoldersSingle /></Layout></RequireAdminAuth>} />

          {/*Authentication*/}
          <Route exact path='/auth' element={<Navigate to="/auth/login" />}/>
          <Route exact path="/auth/:page" element={<Auth />} />

          {/*404*/}
          <Route exact path="/not-authorized" element={<NotAuthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </OzBody>
    );
  // }
}

export default App;
