import React, {useState, useCallback, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
// import axios from 'axios';
import styled from 'styled-components';
import {OverlayContext} from './../../contexts/OverlayContext';


// import { triggerBase64Download } from 'common-base64-downloader-react';

// import {CognitoContext} from '../../contexts/CognitoContext';

import {OzContainer, OzRow, OzCol, OzCard, OzButton, OzTable} from "@ozwol/webui";
//CHETWODEBLUE, BITTERSWEET, OzCopyable

import Meta from '../../components/Meta';

import {OVERLAY_TIMEOUT_ERROR} from '../../config';
import {formatError, changeDate, checkPermission, convertSize, getFormattedDate, getFormattedTime} from '../../helpers';

import ProjectsService from '../../services/ProjectsService';
import WorkspaceService from '../../services/WorkspaceService';
import HotfoldersService from '../../services/HotfoldersService';
import DownloadService from '../../services/DownloadService';
import LogsService from './../../services/LogsService';

import Tree from './parts/Tree';
import TreeFiles from './parts/TreeFiles';
import PopupDownloadPolling from './parts/PopupDownloadPolling';
import OverlayPreview from "./parts/OverlayPreview";

import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalError from '../../components/ModalError';
// import CardHeaderTitle from '../../components/CardHeaderTitle';


const VIEW_VERTICAL = "v";
const VIEW_HORIZONTAL = "h";
const VIEW_TABS = "t";

const Tabs = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  
  // display: none;
  margin: 0px 10px -1px;
  overflow: hidden;
`;

const Tab = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.isActive ? "#303030" : "#30303050"};

  border: 1px solid ${props => props.isActive ? "#d5d9ed" : "transparent" };
  border-bottom: 1px solid transparent;
  box-shadow: 0px 3px 2px ${props => props.isActive ? "#C1CCE838" : "transparent"};
  padding: 8px 20px;
  background-color: ${props => props.isActive ? "#ffffff" : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: ${props => props.isActive ? "default" : "pointer"};

  &:hover{
    background-color: ${props => props.isActive ? "#ffffff" : "#ffffff99"};
    border-bottom: 1px solid ${props => props.isActive ? "#ffffff" : "#d5d9ed"};
  }
`;

const Viewbox = styled.div`
  // border: 1px solid red;
  height: calc(${props => props.half ? 
    "50vh - calc(187px + " + (props.showLogs ? "118px" : "0px") +")" 
  : "100vh - calc(310px + " + (props.showLogs ? "235px" : "0px") +")" 
  }); /*300*/
  overflow-x: hidden;
  overflow-y: scroll;
`;
const Despacer = styled.div`
  margin-bottom: -100px;
`;
const Card = styled.div`
  & > div{
    padding: 10px 10px 10px;
  }
`;
const Views = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  // display: none;
  margin: 0px;
  overflow: hidden;
`;
const ButtonsGroup = styled.div`
  & :not(:first-child){
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  & :not(:last-child){
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }
`;
const Icon = styled.div`
  display: flex;
  & > span{
    font-size: 20px;
  }
`;

/*LOGS*/

const NoLog = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 12px;
  font-weight: 400;
`;
// const Accordion = styled.div`
//   display: flex;
//   font-size: 12px;

//   & table{
//     width: 100%;
//   }
//   & table,
//   & table *{
//     border: 0 !important;
//     background: transparent !important;
//   }
// `;
// const AccordionLeft = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
//   width: ${props => props.full ? "100%" : "60%"};

//   & *{
//     white-space: normal;
//   }
//   & b{
//     white-space: nowrap;
//   }
// `;
// const AccordionRight = styled.div`
//   color: ${BITTERSWEET};
//   flex-grow: 0;
//   flex-shrink: 0;
//   width: 40%;
// `;
// const LogRow = styled.div`
//   display: flex;
//   padding-bottom: 3px;
//   padding-left: 20px;
// `;
// const LogKey = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
//   width: 120px;
//   white-space: nowrap;
//   font-weight: 600;
// `;
// const LogValue = styled.div`
//   flex-grow: 1;
//   flex-shrink: 1;
//   white-space: normal; 
//   width: calc(100% - 120px);
// `;
const LogCost = styled.div`
  text-align: center;
`;
const LogExecution = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  color: ${props => props.status === "ERROR" ? "#FF4848" : props.status === "WARNING" ? "#FFB100" : "#4AD384"};
  margin: 0 auto;
`;
const Dark = styled.div`;
  font-size: 12px;
`;
const Light = styled.div`
  color: #30303099;
  font-size: 12px;
`;
// const Detail = styled.div`
//   text-transform: uppercase;
//   color: ${CHETWODEBLUE};
  
//   font-size: 12px;
// `;
const LogTableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(1),
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
`;

const Webclient = ({refScrollerPage}) => {
  const {projectUuid} = useParams();
  const {addOverlay} = useContext(OverlayContext);
  const [project, setProject] = useState(null);
  // let service = null;

  // const [project, setProject] = useState(null);
  // const [credentials, setCredentials] = useState(null);

  const [side, setSide] = useState("INPUT");
  // const [valid, setValid] = useState(false);

  const [tree, setTree] = useState(null);
  const [openPaths, setOpenPaths] = useState([]);
  const [filesPaths, setFilesPaths] = useState(null);
  const [folderPaths, setFolderPaths] = useState(null);
  const [activeNode, setActiveNode] = useState(null);  
  const [selected, setSelected] = useState([]);  

  const [hfList, setHfList] = useState(false);
  const [logsList, setLogsList] = useState([]);

  const [fetchError, setFetchError] = useState(false);

  const [view, setView] = useState(localStorage.getItem("ozwolConsoleWorkspaceView"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? localStorage.getItem("ozwolConsoleWorkspaceView"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) : VIEW_VERTICAL);
  const [showLogs, setShowLogs] = useState(localStorage.getItem("ozwolConsoleWorkspaceLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) === "t" ? localStorage.getItem("ozwolConsoleWorkspaceLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) === "t" : false);
  const [showLogsButton, setShowLogsButton] = useState(false);
  const [showTemp, setShowTemp] = useState(localStorage.getItem("ozwolConsoleWorkspaceTemp"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) === "t" ? localStorage.getItem("ozwolConsoleWorkspaceTemp"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) === "t" : false);


  useEffect(() => {
    if(checkPermission("project", "read")){
      ProjectsService.get(projectUuid).then((response) => {
        setProject(response);
      }).catch((error) => {
        setFetchError(formatError(error));
      });
    }
  }, [projectUuid]);



  const refreshList = useCallback(() => {
      setLogsList([]);
      setSelected([]);    
      setTree(null);
      setFilesPaths(null);
      WorkspaceService.list().then(response => {
        let result = [];
        let filesPaths = [];
        let folderPaths = [];
        let level = {result};
        for (var i = 0; i < response.length; i++) { 
          let path = response[i];
          if(path.Size === 0){
            path = path.Key.slice(0, -1);
            path.split("/").reduce((r, name, i, a) => {
              if(!r[name]) {
                r[name] = {result: []};
                r.result.push({
                  name, 
                  children: r[name].result, 
                  fullPath: a.slice(0, i+1).join("/")
                })
              }
              return r[name];
            }, level)
            folderPaths.push(path);
          }else{
            // console.log(path.split("/").slice(0,-1));
            path.Key.split("/").slice(0,-1).reduce((r, name, i, a) => {
              // console.log(r);
              if(!r[name]) {
                r[name] = {result: []};
                r.result.push({
                  name, 
                  children: r[name].result, 
                  fullPath: a.slice(0, i+1).join("/")
                })
              }
              return r[name];
            }, level);
            filesPaths.push(path);
          }
        }
        if(!result || result.length === 0 || result[0].children.filter(e => e.name === "HOTFOLDERS").length === 0){
          throw new Error("Missing HOTFOLDERS folder.");
        }else if(result[0].children[result[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.filter(e => e.name === "INPUT").length === 0){
          throw new Error("Missing INPUT folder.");
        }else{
          setTree(result);
          setFilesPaths(filesPaths);   
          setFolderPaths(folderPaths);      
        } 


        /* LOGS */
        if(checkPermission("log", "list")){
          LogsService.list("", {
            "project_uuid": projectUuid,
            "consumer_type": "SCRIPT",
            "start": changeDate(Date.now(), -7, "days"),
          }).then((response) => {
            setLogsList(response.hits);
          }).catch((error) => {
            setLogsList([]);
            setFetchError(formatError(error));
          });
        }

        /* HF */        
      HotfoldersService.list({"projectUuid": projectUuid}).then((response) => {
        setHfList(response.map(i => i.path));
        // console.log(response.result.map(i => i.path));
      }).catch((error) => {
        // setFetchError(formatError(error));
      });

      }).catch(error => {
        setFetchError(formatError(error));
      })
    // }
  }, [projectUuid]); //valid

  useEffect(() => {
    ProjectsService.getWorkspaceCredentials(projectUuid).then((response) => {
      setTree(null);
      setOpenPaths([projectUuid+"/HOTFOLDERS/INPUT", projectUuid+"/HOTFOLDERS/OUTPUT"]);
      setFilesPaths(null);
      setActiveNode(null);
      setFetchError(null);

      if(response.awsAccessKeyId && response.awsSecretAccessKey){
        WorkspaceService.init({...response, projectUuid});
        // console.log("asd");
        // setValid(true);
        refreshList();
      }else{
        // setValid(false);
        throw new Error("Missing credentials!");
      }            
    }).catch((error) => {
      // setCredentials(null);
      setFetchError(formatError(error));
    });
  }, [projectUuid, refreshList]);

  useEffect(() => {
    let foldersToBeDeleted = [];
    if(folderPaths && folderPaths.length > 0 && filesPaths && filesPaths.length > 0 ){

      let showLogsButton = false;
      folderPaths.forEach((folder) => {
        if(folder && folder.toLowerCase().indexOf("_temp")>-1){
          showLogsButton = true;
        }
      });
      filesPaths.forEach((file) => {
        if(file && file.Key && file.Key.toLowerCase().indexOf("_temp")>-1){
          showLogsButton = true;
        }
      });
      setShowLogsButton(showLogsButton);


      folderPaths.forEach((folder) => {
        if(folder.startsWith(projectUuid+"/HOTFOLDERS/OUTPUT/")){
          let toBeDeleted = true;
          filesPaths.forEach((file) => {
            if(file.Key.startsWith(folder)){
              toBeDeleted = false;
            }
          });
          if(toBeDeleted){
            foldersToBeDeleted.push(folder);
          }
        }

      });

      foldersToBeDeleted.forEach((folder) => {
        WorkspaceService.delete(folder+"/").then(response => {
          refreshList();
        });
      });
    }
  }, [folderPaths, filesPaths, projectUuid, refreshList])



  const getFiles = useCallback((filePaths) => {
    filePaths.forEach((item, key)=>{
      filePaths[key]= item.replace(projectUuid+"/","");
    });
    DownloadService.create({
      projectUuid: projectUuid,
      fileList: filePaths
    }).then(response => {
      if(response.downloadUrl){
        const downloadLink = document.createElement('a');
        downloadLink.href = response.downloadUrl;
        downloadLink.target = "_blank";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }else{
        addOverlay({
          reference: "downloadPolling",
          type: "popup",
          content: <PopupDownloadPolling uuid={response.uuid} />,
        });
      }
      setSelected([]);
    }).catch(error => {
      addOverlay({
        reference: "error",
        content: <ModalError>{formatError(error)}</ModalError>,
        timeout: OVERLAY_TIMEOUT_ERROR
      });
    })

  }, [projectUuid, addOverlay]);



  const previewFile = useCallback((filePath) => {
    filePath = filePath.replace(projectUuid+"/","");

    // filesPaths.filter(item => item.)

    DownloadService.create({
      projectUuid: projectUuid,
      fileList: [filePath]
    }).then(response => {
      if(response.downloadUrl){
        addOverlay({
          type: "fullscreen",
          reference: "preview",
          content: <OverlayPreview 
            downloadUrl={response.downloadUrl}
            filePath={filePath} 
            folderPath={filePath.substring(0,filePath.lastIndexOf("/")+1)}
            allFilesPaths={filesPaths.map(item => item.Key.replace(projectUuid+"/","")).filter(item => item.substring(0,item.lastIndexOf("/")+1) === filePath.substring(0,filePath.lastIndexOf("/")+1))}
            onNav={(newFilePath) => previewFile(newFilePath)}
            getFiles={getFiles}
          />,
        });
      }
    }).catch(error => {
      addOverlay({
        reference: "error",
        content: <ModalError>{formatError(error)}</ModalError>,
        timeout: OVERLAY_TIMEOUT_ERROR
      });
    })

  }, [projectUuid, addOverlay, filesPaths, getFiles]);

  const deleteFiles = useCallback((filesPaths) => {
    WorkspaceService.deleteList(filesPaths).then(response => {
      refreshList();
    }).catch(error => {
      setFetchError(formatError(error));
    })
    // filePaths.forEach((path, key)=>{
    //   WorkspaceService.delete(path).then(response => {
    //     refreshList();
    //   }).catch(error => {
    //     setFetchError(formatError(error));
    //   })
    // });

  }, [refreshList]);

  const deleteFile = useCallback((path) => {
    WorkspaceService.delete(path).then(response => {
      refreshList();
    }).catch(error => {
      setFetchError(formatError(error));
    })

  }, [refreshList]);

  const retryFile = useCallback((path) => {
    WorkspaceService.retry(path).then(response => {
      refreshList();
    }).catch(error => {
      setFetchError(formatError(error));
    })
  }, [refreshList]);

  const retryFolder = useCallback((path) => {
    Promise.all(
      filesPaths.filter(item => item.Key.startsWith(path+"/")).map(item => WorkspaceService.retry(item.Key))
    ).then(response => {
      refreshList();
    }).catch(error => {
      setFetchError(formatError(error));
    })      
  }, [filesPaths, refreshList]);

  const deleteFolder = useCallback((path) => {
    WorkspaceService.deleteFolder(path).then(response => {
      refreshList();
    }).catch(error => {
      setFetchError(formatError(error));
    })

  }, [refreshList]);

  const purgeFolder = useCallback((path) => {
    WorkspaceService.deleteList(filesPaths.filter((item => item.Key.startsWith(path+"/"))).map(item => item.Key)).then(response => {
      refreshList();
    }).catch(error => {
      setFetchError(formatError(error));
    })

  }, [filesPaths, refreshList]);
  

  return (
    <>
      <OzContainer size="extra">
        <Meta title={"Workspace - Hotfolders"} />
        <PageHeader
          breadcrumbsIcon="perm_media"
          breadcrumbsText={["Hotfolders", project ? project.name : "", project ? project.uuid : ""]}
          refScrollerPage={refScrollerPage}
          
          buttons={ 
            [
              {
                "icon": "download",
                "text": "Download" + (selected.length ? " ("+selected.length+")" : ""),
                "onClick": () => getFiles(selected),
                "disabled": selected.length === 0
              },
              {
                "icon": "delete",
                "text": "Delete" + (selected.length ? " ("+selected.length+")" : ""),
                "prompt": "DELETE",
                "onClick": () => deleteFiles(selected),
                "disabled": selected.length === 0
              },
              {
                "icon": "refresh",
                "text": "Refresh",
                "onClick": () => refreshList()
              }
            ]
          }
        />

        <HealthyWrapper error={fetchError}>
          <OzRow>
            <OzCol>
              <Views>
                {checkPermission("log", "list") ? 
                  <OzButton variant={"push-black"} pushed={showLogs} onClick={() => {
                    localStorage.setItem("ozwolConsoleWorkspaceLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), showLogs ? "f" : "t");
                    setShowLogs(!showLogs); 
                  }} noMinWidth><Icon><span className="material-symbols-outlined">checklist_rtl</span></Icon> Logs</OzButton>
                : null}
                
                

                {showLogsButton ? 
                  <OzButton variant={"push-black"} pushed={showTemp} onClick={() => {
                    localStorage.setItem("ozwolConsoleWorkspaceTemp"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), showTemp ? "f" : "t");
                    setShowTemp(!showTemp); 
                  }} noMinWidth><Icon><span className="material-symbols-outlined">folder_off</span></Icon> Temp</OzButton>
                : null}
              
                <ButtonsGroup>
                  <OzButton variant={"push-black"} pushed={view === VIEW_VERTICAL} onClick={() => {
                    setView(VIEW_VERTICAL); 
                    localStorage.setItem("ozwolConsoleWorkspaceView"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)),VIEW_VERTICAL);
                  }} noMinWidth><Icon><span className="material-symbols-outlined">splitscreen_left</span></Icon></OzButton>
                  <OzButton variant={"push-black"} pushed={view === VIEW_HORIZONTAL} onClick={() => {
                    setView(VIEW_HORIZONTAL); 
                    localStorage.setItem("ozwolConsoleWorkspaceView"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)),VIEW_HORIZONTAL);
                  }} noMinWidth><Icon><span className="material-symbols-outlined">splitscreen_top</span></Icon></OzButton>
                  <OzButton variant={"push-black"} pushed={view === VIEW_TABS} onClick={() => {
                    setView(VIEW_TABS); 
                    localStorage.setItem("ozwolConsoleWorkspaceView"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)),VIEW_TABS);
                  }} noMinWidth><Icon><span className="material-symbols-outlined">tab_group</span></Icon></OzButton>
                </ButtonsGroup>
              </Views>              
              {view === VIEW_TABS ? 
                <>                
                  <Tabs>
                    <Tab isActive={side === "INPUT"} onClick={() => setSide("INPUT")}>Input</Tab>
                    <Tab isActive={side === "OUTPUT"} onClick={() => setSide("OUTPUT")}>Output</Tab>
                  </Tabs>
                  <Card>
                    <OzCard>
                      <Viewbox showLogs={showLogs}>
                        {tree && 
                        tree.length > 0 && 
                        !fetchError &&
                        tree[0].children.filter(e => e.name === "HOTFOLDERS").length > 0 &&
                        tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.filter(e => e.name === side).length > 0
                        ?
                          tree[0] //PROJECT
                            .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                            .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf(side)]    //INPUT
                            .children                      
                            .map((item, i) => 
                              <Tree 
                                key={i} 
                                previewFile={previewFile}
                                deleteFile={deleteFile} 
                                retryFile={retryFile}
                                deleteFolder={deleteFolder}
                                retryFolder={retryFolder}
                                purgeFolder={purgeFolder}
                                getFiles={getFiles} 
                                setActiveNode={setActiveNode} 
                                activeNode={activeNode} 
                                filesPaths={filesPaths} 
                                item={item} 
                                level={0}
                                openPaths={openPaths}
                                setOpenPaths={setOpenPaths}
                                hfList={hfList}
                                selected={selected}
                                setSelected={setSelected}
                                refreshList={refreshList}
                                showTemp={showTemp}
                                logsList={logsList}
                              />
                            )
                        : null}
                        {tree ? 
                          <TreeFiles 
                            item={
                              tree[0] //PROJECT
                              .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                              .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf(side)]
                            }
                            previewFile={previewFile}
                            deleteFile={deleteFile} 
                            retryFile={retryFile}
                            getFiles={getFiles}
                            filesPaths={filesPaths}
                            openPaths={openPaths}
                            selected={selected}
                            setSelected={setSelected}
                            showTemp={showTemp}
                            logsList={logsList}
                          />
                        : null}
                      </Viewbox>
                    </OzCard>
                  </Card>
                </>
              : view === VIEW_VERTICAL ?
                <>      
                  <OzRow gap={8}>
                    <OzCol width={6}>          
                      <Tabs>
                        <Tab isActive={true}>Input</Tab>
                      </Tabs>
                      <Card>
                        <OzCard>
                          <Viewbox showLogs={showLogs}>
                            {tree && 
                            tree.length > 0 && 
                            !fetchError &&
                            tree[0].children.filter(e => e.name === "HOTFOLDERS").length > 0 &&
                            tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.filter(e => e.name === "INPUT").length > 0
                            ?
                              tree[0] //PROJECT
                                .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("INPUT")]    //INPUT
                                .children                      
                                .map((item, i) => 
                                  <Tree 
                                    key={i} 
                                    previewFile={previewFile}
                                    deleteFile={deleteFile} 
                                    retryFile={retryFile}
                                    deleteFolder={deleteFolder}
                                    retryFolder={retryFolder}
                                    purgeFolder={purgeFolder}
                                    getFiles={getFiles} 
                                    setActiveNode={setActiveNode} 
                                    activeNode={activeNode} 
                                    filesPaths={filesPaths} 
                                    item={item} 
                                    level={0}
                                    openPaths={openPaths}
                                    setOpenPaths={setOpenPaths}
                                    hfList={hfList}
                                    selected={selected}
                                    setSelected={setSelected}
                                    refreshList={refreshList}
                                    logsList={logsList}
                                    showTemp={showTemp}
                                  />
                                )
                            : null}
                            {tree ? 
                              <TreeFiles 
                                item={
                                  tree[0] //PROJECT
                                  .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                  .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("INPUT")]
                                }
                                previewFile={previewFile}
                                deleteFile={deleteFile} 
                                retryFile={retryFile}
                                getFiles={getFiles}
                                filesPaths={filesPaths}
                                openPaths={openPaths}
                                selected={selected}
                                setSelected={setSelected}
                                logsList={logsList}
                                showTemp={showTemp}
                              />
                            : null}
                          </Viewbox>
                        </OzCard>
                      </Card>
                    </OzCol>
                    <OzCol width={6}>          
                      <Tabs>
                        <Tab isActive={true}>Output</Tab>
                      </Tabs>
                      <Card>
                        <OzCard>
                          <Viewbox showLogs={showLogs}>
                            {tree && 
                            tree.length > 0 && 
                            !fetchError &&
                            tree[0].children.filter(e => e.name === "HOTFOLDERS").length > 0 &&
                            tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.filter(e => e.name === "OUTPUT").length > 0
                            ?
                              tree[0] //PROJECT
                                .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("OUTPUT")]    //INPUT
                                .children                      
                                .map((item, i) => 
                                  <Tree 
                                    key={i} 
                                    previewFile={previewFile}
                                    deleteFile={deleteFile} 
                                    retryFile={retryFile}
                                    deleteFolder={deleteFolder}
                                    retryFolder={retryFolder}
                                    purgeFolder={purgeFolder}
                                    getFiles={getFiles} 
                                    setActiveNode={setActiveNode} 
                                    activeNode={activeNode} 
                                    filesPaths={filesPaths} 
                                    item={item} 
                                    level={0}
                                    openPaths={openPaths}
                                    setOpenPaths={setOpenPaths}
                                    hfList={hfList}
                                    selected={selected}
                                    setSelected={setSelected}
                                    refreshList={refreshList}
                                    logsList={logsList}
                                    showTemp={showTemp}
                                  />
                                )
                            : null}
                            {tree ? 
                              <TreeFiles 
                                item={
                                  tree[0] //PROJECT
                                  .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                  .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("OUTPUT")]
                                }
                                previewFile={previewFile}
                                deleteFile={deleteFile} 
                                retryFile={retryFile}
                                getFiles={getFiles}
                                filesPaths={filesPaths}
                                openPaths={openPaths}
                                selected={selected}
                                setSelected={setSelected}
                                logsList={logsList}
                                showTemp={showTemp}
                              />
                            : null}
                          </Viewbox>
                        </OzCard>
                      </Card>
                    </OzCol>
                  </OzRow>
                </>
              : view === VIEW_HORIZONTAL ?
                <>      
                  <OzRow gap={8}>
                    <OzCol width={12}>          
                      <Tabs>
                        <Tab isActive={true}>Input</Tab>
                      </Tabs>
                      <Card>
                        <OzCard>
                          <Viewbox showLogs={showLogs} half>
                            {tree && 
                            tree.length > 0 && 
                            !fetchError &&
                            tree[0].children.filter(e => e.name === "HOTFOLDERS").length > 0 &&
                            tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.filter(e => e.name === "INPUT").length > 0
                            ?
                              tree[0] //PROJECT
                                .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("INPUT")]    //INPUT
                                .children                      
                                .map((item, i) => 
                                  <Tree 
                                    key={i} 
                                    previewFile={previewFile}
                                    deleteFile={deleteFile} 
                                    retryFile={retryFile}
                                    deleteFolder={deleteFolder}
                                    retryFolder={retryFolder}
                                    purgeFolder={purgeFolder}
                                    getFiles={getFiles} 
                                    setActiveNode={setActiveNode} 
                                    activeNode={activeNode} 
                                    filesPaths={filesPaths} 
                                    item={item} 
                                    level={0}
                                    openPaths={openPaths}
                                    setOpenPaths={setOpenPaths}
                                    hfList={hfList}
                                    selected={selected}
                                    setSelected={setSelected}
                                    refreshList={refreshList}
                                    logsList={logsList}
                                    showTemp={showTemp}
                                  />
                                )
                            : null}
                            {tree ? 
                              <TreeFiles 
                                item={
                                  tree[0] //PROJECT
                                  .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                  .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("INPUT")]
                                }
                                previewFile={previewFile}
                                deleteFile={deleteFile} 
                                retryFile={retryFile}
                                getFiles={getFiles}
                                filesPaths={filesPaths}
                                openPaths={openPaths}
                                selected={selected}
                                setSelected={setSelected}
                                logsList={logsList}
                                showTemp={showTemp}
                              />
                            : null}
                          </Viewbox>
                        </OzCard>
                      </Card>
                    </OzCol>
                    <OzCol width={12}>          
                      <Tabs>
                        <Tab isActive={true}>Output</Tab>
                      </Tabs>
                      <Card>
                        <OzCard>
                          <Viewbox showLogs={showLogs} half>
                            {tree && 
                            tree.length > 0 && 
                            !fetchError &&
                            tree[0].children.filter(e => e.name === "HOTFOLDERS").length > 0 &&
                            tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.filter(e => e.name === "OUTPUT").length > 0
                            ?
                              tree[0] //PROJECT
                                .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("OUTPUT")]    //INPUT
                                .children                      
                                .map((item, i) => 
                                  <Tree 
                                    key={i} 
                                    previewFile={previewFile}
                                    deleteFile={deleteFile} 
                                    retryFile={retryFile}
                                    deleteFolder={deleteFolder}
                                    retryFolder={retryFolder}
                                    purgeFolder={purgeFolder}
                                    getFiles={getFiles} 
                                    setActiveNode={setActiveNode} 
                                    activeNode={activeNode} 
                                    filesPaths={filesPaths} 
                                    item={item} 
                                    level={0}
                                    openPaths={openPaths}
                                    setOpenPaths={setOpenPaths}
                                    hfList={hfList}
                                    selected={selected}
                                    setSelected={setSelected}
                                    refreshList={refreshList}
                                    logsList={logsList}
                                    showTemp={showTemp}
                                  />
                                )
                            : null}
                            {tree ? 
                              <TreeFiles 
                                item={
                                  tree[0] //PROJECT
                                  .children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")] //HOTFOLDERS
                                  .children[tree[0].children[tree[0].children.map(e => e.name).indexOf("HOTFOLDERS")].children.map(e => e.name).indexOf("OUTPUT")]
                                }
                                previewFile={previewFile}
                                deleteFile={deleteFile} 
                                retryFile={retryFile}
                                getFiles={getFiles}
                                filesPaths={filesPaths}
                                openPaths={openPaths}
                                selected={selected}
                                setSelected={setSelected}
                                logsList={logsList}
                                showTemp={showTemp}
                              />
                            : null}
                          </Viewbox>
                        </OzCard>
                      </Card>
                    </OzCol>
                  </OzRow>
                </>
              : null }
            </OzCol>
            {showLogs && checkPermission("log", "list") ? 
              <OzCol>
                <OzCard
                  height="100%"
                  // headerLeft={<CardHeaderTitle title="Last logs" />}
                  // footerRight={
                  //   <Link to={"/project/"+projectUuid+"/logs"}>
                  //     <Detail>Detail</Detail>
                  //   </Link>
                  // }
                >
                  {logsList && logsList.length > 0 ?
                    <LogTableWrapper>
                      <OzTable
                        columns={[
                          "Date",
                          "Time",
                          "File name",
                          "Activity",
                          "Size",
                          <center>Cost</center>,
                          <center>Exec</center>
                        ]}
                        values={
                          logsList.slice(0,2).map(item => ({
                            style: {
                              // color: item.result === "ERROR" ? "#FF4848" : null
                            },
                            id: item.document.id,
                            // accordion: <Accordion>
                            //   <AccordionLeft full={item.document.result !== "ERROR" && item.document.result !== "WARNING"}>
                            //     <table>
                            //       <LogRow>
                            //         <LogKey>Date</LogKey>
                            //         <LogValue>{getFormattedDate(item.document.create_datetime)}</LogValue>
                            //       </LogRow>
                            //       <LogRow>
                            //         <LogKey>Time</LogKey>
                            //         <LogValue>{getFormattedTime(item.document.create_datetime)}</LogValue>
                            //       </LogRow>
                            //       {item.document.source_file_key ? 
                            //         <LogRow>
                            //           <LogKey>Source file name</LogKey>
                            //           <LogValue>
                            //             <OzCopyable>{item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)}</OzCopyable>
                            //           </LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.source_file_size ? 
                            //         <LogRow>
                            //           <LogKey>Source file size</LogKey>
                            //           <LogValue>{convertSize(item.document.source_file_size)}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {project ? 
                            //         <LogRow>
                            //           <LogKey>Project</LogKey>
                            //           <LogValue>{project.name}
                            //           </LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.project_uuid ? 
                            //         <LogRow>
                            //           <LogKey>Project UUID</LogKey>
                            //           <LogValue> 
                            //             <OzCopyable>{item.document.project_uuid}</OzCopyable>
                            //           </LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.consumer_type ? 
                            //         <LogRow>
                            //           <LogKey>Consumer type</LogKey>
                            //           <LogValue>{item.document.consumer_type}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.script_name ? 
                            //         <LogRow>
                            //           <LogKey>Script</LogKey>
                            //           <LogValue>{item.document.script_name}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.destination_type ? 
                            //         <LogRow>
                            //           <LogKey>Destination type</LogKey>
                            //           <LogValue>{item.document.destination_type}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.processing_time_ms ? 
                            //         <LogRow>
                            //           <LogKey>Process time (ms)</LogKey>
                            //           <LogValue>{item.document.processing_time_ms}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.response_size ? 
                            //         <LogRow>
                            //           <LogKey>Response size</LogKey>
                            //           <LogValue>{convertSize(item.document.response_size)}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.assets_processed ? 
                            //         <LogRow>
                            //           <LogKey>Assets processed</LogKey>
                            //           <LogValue>{item.document.assets_processed}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.credit_price ? 
                            //         <LogRow>
                            //           <LogKey>Credit price</LogKey>
                            //           <LogValue>{item.document.credit_price}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.transfer_bytes ? 
                            //         <LogRow>
                            //           <LogKey>Transfer bytes</LogKey>
                            //           <LogValue>{convertSize(item.document.transfer_bytes)}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.storage_bytes ? 
                            //         <LogRow>
                            //           <LogKey>Storage bytes</LogKey>
                            //           <LogValue>{convertSize(item.document.storage_bytes)}</LogValue>
                            //         </LogRow>
                            //       : null}
                            //       {item.document.job_uuid ? 
                            //         <LogRow>
                            //           <LogKey>Job UUID</LogKey>
                            //           <LogValue>
                            //             <OzCopyable>{item.document.job_uuid}</OzCopyable>
                            //           </LogValue>
                            //         </LogRow>
                            //       : null}
                            //     </table>
                            //   </AccordionLeft>
                            //   {item.document.result === "ERROR" || item.document.result === "WARNING" ?
                            //     <AccordionRight>
                                
                            //       <table>
                            //         {item.document.error_code ? 
                            //           <LogRow>
                            //             <LogKey>Error</LogKey>
                            //             <LogValue>{item.document.error_code}</LogValue>
                            //           </LogRow>
                            //         : null}
                            //         {item.document.error_description ? 
                            //           <LogRow>
                            //             <LogKey>Description</LogKey>
                            //             <LogValue>{item.document.error_description}</LogValue>
                            //           </LogRow>
                            //         : null}
                            //         {item.document.error_scope ? 
                            //           <LogRow>
                            //             <LogKey>Type</LogKey>
                            //             <LogValue>{item.document.error_scope}</LogValue>
                            //           </LogRow>
                            //         : null}
                            //       </table>
                            //     </AccordionRight>
                            //   : null}
                            // </Accordion>,
                            values: [
                              <Dark>
                                {getFormattedDate(item.document.create_datetime)}
                              </Dark>,
                              <Light>
                                {getFormattedTime(item.document.create_datetime)}
                              </Light>,
                              <Dark>
                                {item.document.source_file_key ? 
                                  ((item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1))
                                  .length > 20 ? 
                                    item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)
                                    .slice(0, 17)
                                    +"..."
                                  :
                                    item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)
                                  )
                                : null}
                              </Dark>,
                              <Dark>
                                {item.document.consumer_type === "SCRIPT" ? 
                                  item.document.script_name
                                :
                                  item.document.consumer_type
                                }
                              </Dark>,
                              item.document.consumer_type === "STORAGE" ?
                                convertSize(item.document.storage_in_use)
                              : item.document.consumer_type === "TRANSFER" ?
                                convertSize(item.document.transfer_consumed)
                              :
                                convertSize(item.document.source_file_size),
                              <LogCost>{item.document.credit_price}</LogCost>,
                              <LogExecution status={item.document.result}>
                                <span className="material-symbols-outlined">{
                                  item.document.result === "SUCCESS" ? 
                                    "done"
                                  : item.document.result === "WARNING" ? 
                                    "warning"
                                  : item.document.result === "ERROR" ? 
                                    "close"
                                  : item.document.result === "ONGOING" ? 
                                    "hourglass_empty"
                                  : null
                                }</span>
                              </LogExecution>
                            ]
                          }))
                        }
                      />
                    </LogTableWrapper>
                  :
                    <NoLog>No log during this period.</NoLog>
                  }
                </OzCard>
              </OzCol>
            : null }
              
          </OzRow> 
        </HealthyWrapper>
      </OzContainer>
      <Despacer />
    </>
  )
}

export default Webclient;
