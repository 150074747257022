import React, {useState, useEffect, useContext}  from 'react';
// import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {OverlayContext} from './../../contexts/OverlayContext';
import {CognitoContext} from '../../contexts/CognitoContext';

import AccountService from './../../services/AccountService';
import VersionService from './../../services/VersionService';
import IubendaService from './../../services/IubendaService';

import ErrorMessage from '../../components/ErrorMessage';

import {CHETWODEBLUE, GHOSTWHITE, CHARCOAL, OzCard, OzContainer, OzIcon, OzButton} from "@ozwol/webui";

import {getFormattedDateTime} from './../../helpers';

const Wrapper = styled.footer`
  text-align: center;
  color: #a5a5a5;
  font-size: 13px;
`;

// const Nav = styled(Link)`
//   color: inherit;

//   font-weight: 400;

//   &:hover{
//     text-decoration: underline;
//     color: ${CHARCOAL};
//   }
// `;

const Anchor = styled.span`
  cursor: pointer;

  &:hover {
    color: ${CHARCOAL};
  }
`;
const Close = styled.div`
  cursor: pointer;
`;

const IubendaWrapper = styled.span`
  display: block;
  max-height: 55vh;
  overflow-y: scroll;
  margin-bottom: 20px;
  
  

  & *{
    font-family: 'Be Vietnam Pro', sans-serif !important;
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
    font-variant: normal !important;


    text-align: left !important;
  }

  & bold, 
  & b{
    font-weight: 600 !important;
  }

  & p, 
  & li{
    font-size: 12px !important;
    color: #303030 !important;
    line-height: 18px !important;
  }

  & .iubenda-button{
    display: none !important;
    opacity: 0 !important;
  }

  & .iub_content,
  & .iub_container,
  & .iub_footer {
    margin: 0px !important;
    padding: 0px !important;
  }

  & h1,
  & h1 strong{
    color: #00141F !important;
    font-size: 20px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
    color: ${CHETWODEBLUE} !important;

  }
  & h2{
    color: #00141F !important;
    font-size: 20px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 0px !important;
    color: ${CHETWODEBLUE} !important;
  }
  & h3{

  }

  & .box_primary{
    background: ${GHOSTWHITE} !important; 
  }
`;

const Versions = styled.div`
  font-size: 10px;
  margin-top: 3px;
`;




const Footer = ({enableIubenda = true}) => {
  const consoleVersion = require('./../../../package.json').version; 
  
  const {addOverlay, removeOverlay} = useContext(OverlayContext);
  const [showIubenda, toggleIubenda] = useState(false);
  const {cognito} = useContext(CognitoContext);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [version, setVersion] = useState(null);
  const [terms, setTerms] = useState(null);
  const [privacy, setPrivacy] = useState(null);

  const getUpdateDate = (htmlString) => {
    // extract the date part using a regex
    const dateMatch = htmlString.match(/Ultima modifica:\s*(\d{1,2}) (\w+) (\d{4})/i);
    if (!dateMatch) {
        throw new Error("Date not found in the input string");
    }

    const day = parseInt(dateMatch[1], 10);
    const monthName = dateMatch[2].toLowerCase();
    const year = parseInt(dateMatch[3], 10);

    // map Italian month names to JavaScript month indices (0-11)
    const months = {
        "gennaio": 0, "febbraio": 1, "marzo": 2, "aprile": 3, "maggio": 4, "giugno": 5,
        "luglio": 6, "agosto": 7, "settembre": 8, "ottobre": 9, "novembre": 10, "dicembre": 11
    };

    const month = months[monthName];
    if (month === undefined) {
        throw new Error("Invalid month name");
    }

    // create and return the Date object
    return new Date(year, month, day);
  }

  useEffect(() => {
    VersionService.getVersion().then((response) => {
      setVersion(response.result);
    }).catch((error) => {
      setError("There was an error when loading data.");
    }); 
  }, []);

  useEffect(() => {
    if(!cognito.groups.includes("OzwolCoworker")){
      AccountService.getUser().then((response) => {
        setUser(response);
      }).catch((error) => {
        setError("There was an error when loading data.");
      }); 
    }
  }, [cognito]);
  useEffect(() => {
    if(enableIubenda){
      IubendaService.getTerms().then((response) => {
        setTerms(response.content);
      }).catch((error) => {
        setError("There was an error when loading data.");
      }); 
    }
  }, [terms, enableIubenda]);
  useEffect(() => {
    if(enableIubenda){
      IubendaService.getPrivacy().then((response) => {
        setPrivacy(response.content);
      }).catch((error) => {
        setError("There was an error when loading data.");
      }); 
    }
  }, [privacy, enableIubenda]);

  // AccountService.updateUser({
  //   contractAcceptanceDatetime: null
  // })
  
  useEffect(() => {
    if(!localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))){
      if(user && terms){
        if((!user.contractAcceptanceDatetime || getUpdateDate(terms) > new Date(user.contractAcceptanceDatetime)) && !showIubenda) { //check su data in futuro
          addOverlay({
            reference: "iubenda-contract",
            content: 
              <OzContainer size="medium">
                <OzCard
                  headerLeft={!user.contractAcceptanceDatetime ? "Please accept the terms to continue" : "The terms have been updated, please review and accept them again"}
                  headerSeparator={true}
                  footerRight={!error ? <OzButton variant={"chetwodeblue"} onClick={() => {
                    AccountService.updateUser({
                      contractAcceptanceDatetime: new Date(),
                      // contractAccepted: terms
                    }).then((response) => {
                      removeOverlay("iubenda-contract");  
                      AccountService.getUser().then((response) => {
                        setUser(response);
                      }).catch((error) => {
                        setError("There was an error when loading data.");
                      });
                    }).catch((error) => {
                      setError("Something went wrong.");
                    });
                  }}>Accept and continue</OzButton> : null}
                  footerLeft={!error ? <OzButton variant={"bittersweet-o"} onClick={() => {
                    AccountService.updateUser({
                      
                    }).then((response) => {
                      removeOverlay("iubenda-contract");  
                      window.location.replace("/auth/login");
                    }).catch((error) => {
                      setError("Something went wrong.");
                    });
                  }}>Deny, my projects will be disabled</OzButton> : null}          
                  footerCenter={
                    <ErrorMessage>{error}</ErrorMessage>
                  }
                >
                  <IubendaWrapper dangerouslySetInnerHTML={{__html: terms}}/>
                </OzCard>
              </OzContainer>,
              isCloseable: false

          });
          toggleIubenda(true);
        } 
      }
    }
  }, [user, error, addOverlay, removeOverlay, showIubenda, terms]);

  return (
    <Wrapper>
      <OzContainer size="extra">
        {enableIubenda ? 
          <>
            <Anchor onClick={() => {
              addOverlay({
                reference: "iubenda-terms",
                content:
                  <OzContainer size="medium">
                    <OzCard
                      headerLeft={"Terms of service"}
                      headerSeparator={true}
                      headerRight={<Close onClick={() => {removeOverlay("iubenda-terms"); toggleIubenda(false);}}><OzIcon name="x"/></Close>}
                      footerLeft={user ?  "Your contract acceptance date: " + getFormattedDateTime(user.contractAcceptanceDatetime) : null}
                    >
                      <IubendaWrapper dangerouslySetInnerHTML={{__html: terms}}/>
                    </OzCard>
                  </OzContainer>
              });
              toggleIubenda(true);
            }}>Terms of service</Anchor> &nbsp; <Anchor onClick={() => {
              addOverlay({
                reference: "iubenda-privacy",
                content:
                  <OzContainer size="medium">
                    <OzCard
                      headerLeft={"Privacy Policy"}
                      headerSeparator={true}
                      headerRight={<Close onClick={() => {removeOverlay("iubenda-privacy"); toggleIubenda(false);}}><OzIcon name="x"/></Close>}
                    >
                      <IubendaWrapper dangerouslySetInnerHTML={{__html: privacy}}/>
                    </OzCard>
                  </OzContainer>
              });
              toggleIubenda(true);
            }}>Privacy policy</Anchor> &nbsp; Ozwol Technologies<br/>
          </>
        : null }
        <Versions>Console V. {consoleVersion}{version && version.platform_version ? " - Platform V. "+version.platform_version : null}{version && version.engine_version ? " - Engine V. "+version.engine_version : null}</Versions>
      </OzContainer>
    </Wrapper>
  )
}

export default Footer;
