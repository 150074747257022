import React from 'react';
// import axios from 'axios';
import styled from 'styled-components';
// import { FileUploader } from "react-drag-drop-files";
import {useParams} from 'react-router-dom';

// import {CognitoContext} from '../../contexts/CognitoContext';

import {LILAC, BLACKPEARL, SPANISHGREY, CHETWODEBLUE, SILVERTREE, OzCheck, OzTag} from "@ozwol/webui";


import {isSameDate, getFormattedDate, getFormattedTime} from '../../../helpers';

// import {formatError} from '../../../helpers';

import FileUpload from './FileUpload';
import TreeFiles from './TreeFiles';


const TreeWrapper = styled.div`
  // padding-left: ${props => props.level * 10}px;
  // border: 1px solid lime;
  user-select: none;
`;
const TreeItem = styled.div`
  position: relative;
  // border: 1px solid lime;
  border-bottom: 1px dashed #dedede;
  display: flex;
  justify-content: space-between;
  cursor: ${props => props.onClick ? "pointer" : "default"};
 
  &:hover{
    background-color: ${LILAC};
  }

  background-color: ${props => props.isSelected ? LILAC : "transparent"};

`;
const TreeItemArrow = styled.div`
  width: 26px;
  height: 26px;
  margin-top: 1px;
  margin-right: -3px;
  cursor: ${props => props.onClick ? "pointer" : "default"};
  rotate: ${props => props.rotate === "true" ? 90 : 0}deg;
  transition: all 0.05s ease-in-out;
  flex-grow: 0;
  flex-shrink: 0;
`;

const TreeItemCheck = styled.div`
  margin-right: 5px;
  margin-left: 1px;
`;


const TreeItemIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-right: -3px;
  // margin-right: 5px;
  // background: lime;
  cursor: ${props => props.onClick ? "pointer" : "default"};
  color: ${props => props.isHotfolder ? SILVERTREE :  props.isFile ? BLACKPEARL : props.isOutput ? CHETWODEBLUE : SPANISHGREY};
  margin-bottom: -1px;
  flex-grow: 0;
  flex-shrink: 0;

  & span{
    // background: red;
    display: block;
  }

`;
const TreeItemRow = styled.div`
  color: ${props => props.isHotfolder || props.isFile ? BLACKPEARL : SPANISHGREY};
  cursor: ${props => props.isFile ? "pointer" : "inherit"};
  padding: 0px 0px 0px ${props => props.level * 15}px;
  display: flex;
  align-items: center;
  width: 100%;
  ${props => props.isFile && props.isOutput ? `
    &:hover{
      color: ${CHETWODEBLUE};
    }  
  ` : null};

  
  
  & label{
    cursor: ${props => props.isHotfolder && props.isOutput ? "pointer" : "default"};
    pointer-events: ${props => props.isHotfolder && props.isOutput ? "auto" : "none"};
    width: 100%;
  }
  & label > div:nth-last-child(2){
    border: 2px dashed ${CHETWODEBLUE};
    background-color: ${CHETWODEBLUE}66;
    border-radius: 0;
    opacity: 1;
  }
  & label span{
    display: none;
  }
`;
const TreeItemName = styled.div`
  padding: 8px 15px 8px 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 
`;
const TreeItemDate = styled.div`
  padding: 0px 15px 0px 0px;
  white-space: nowrap;
  font-size: 11px;
  width: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  font-family: "DM Mono", serif;
  text-align: right;
  display: flex;
  align-items: center;
`;
const TreeItemTime = styled.div`
  padding: 0px 15px 0px 0px;
  white-space: nowrap;
  font-size: 11px;
  width: 70px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  font-family: "DM Mono", serif;
  text-align: right;
  display: flex;
  align-items: center;
`;
// const TreeItemCounter = styled.div`
//   // color: #fff;
//   background-color: ${BORDER};
//   padding: 0px 6px;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
// `;
// const TreeItemRemove = styled.div`
//   color: #fff;
//   background-color: ${BITTERSWEET};
//   padding: 0px 6px;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
//   opacity: 0;
  
//   div:hover > &{
//     opacity: 1;
//   }
//   ${props => props.onClick ? `
//     cursor: pointer;
//   ` : `
//     opacity: 0 !important;
//   `};
// `;




const Tree = ({
	item, 
	getFiles, 
  previewFile,
	deleteFile, 
  retryFile,
  deleteFolder,
  retryFolder,
  purgeFolder,
	setActiveNode, 
	activeNode, 
	filesPaths, 
	openPaths, 
	setOpenPaths, 
	mode = 0, 
	level = 0,
  hfList,
  selected,
  setSelected,
  refreshList,
  showTemp,
  logsList
}) => {
  const {projectUuid} = useParams();
// console.log(openPaths);
// console.log(item);
  // const [fetchError, setFetchError] = useState(false);

	const isFolderSelected = (folderPath) => {
    // console.log(folderPath+"/");
    // console.log(filesPaths.map(item=>item.Key));
    let tempSelecteds = selected.filter(item => item===folderPath || item.startsWith(folderPath+"/"));
    let tempFiles = filesPaths.map(item=>item.Key).filter(item => item.startsWith(folderPath+"/"));
    
    return tempSelecteds.length === 0 ? false : tempSelecteds.length === tempFiles.length ? true : null;
	}
	const toggleFolderSelection = (folderPath) => {
    let tempSelecteds = selected.filter(item => item===folderPath || item.startsWith(folderPath+"/"));
    let tempFiles = filesPaths.map(item=>item.Key).filter(item => item.startsWith(folderPath+"/"));
    
    if(tempSelecteds.length === tempFiles.length){
      setSelected(selected.filter(item => !item.startsWith(folderPath+"/")))
    }else{
      setSelected(selected.filter(item => !item.startsWith(folderPath+"/")).concat(tempFiles))
    }
	}


	const isOutput = (item) => {
		return item && item.fullPath.startsWith(projectUuid + "/HOTFOLDERS/OUTPUT")
	}
	const isHotfolder = (item) => {
		return hfList && hfList.filter(folder => (item.fullPath+"/") === (projectUuid + "/" + folder)).length > 0;
	}
	// const isHotfolderSubfolder = (item) => {
	// 	return hfList && hfList.filter(folder => (item.fullPath+"/").startsWith(projectUuid + "/" + folder) && (item.fullPath+"/") !== (projectUuid + "/" + folder)).length > 0;
	// }
	// const isLeaf = (item) => {
	// 	return item && item.children.length === 0 && !item.fullPath.startsWith(projectUuid + "/HOTFOLDERS/OUTPUT")
	// }

  // useEffect(() => {
  //   console.log(openPaths);
  // }, [openPaths]);


  return (
    showTemp || item.name.toLowerCase().slice(0,5) !== "_temp" ? 
      <TreeWrapper>
        <>
          <TreeItem 
            isHotfolder={isHotfolder(item)} 
            level={level} 
            isSelected={isFolderSelected(item.fullPath)}
          >
            
            <TreeItemRow 
              level={level}
              isHotfolder={isHotfolder(item)}
              isFile={false}
              isOutput={isOutput(item)}
            >
              
              <TreeItemArrow 
                rotate={openPaths.includes(item.fullPath) ? "true" : "false"} 
                onClick={() => {
                  openPaths.includes(item.fullPath) ?
                    setOpenPaths(openPaths.filter(x => x !== item.fullPath))
                  :
                    setOpenPaths(openPaths.concat([item.fullPath]))
                }
              }>
                <span className="material-symbols-outlined">chevron_right</span>
              </TreeItemArrow>
              {isOutput(item) ?
                <TreeItemCheck>
                  <OzCheck
                    tristate={true}
                    selected={isFolderSelected(item.fullPath)}
                    onChange={() => toggleFolderSelection(item.fullPath)}
                  />
                </TreeItemCheck>
              : null }
              <TreeItemIcon
                isHotfolder={isHotfolder(item)}
                isFile={false}
                isOutput={isOutput(item)}
                onClick={() => {
                  openPaths.includes(item.fullPath) ?
                    setOpenPaths(openPaths.filter(x => x !== item.fullPath))
                  :
                    setOpenPaths(openPaths.concat([item.fullPath]))
                }
              }>
                <span className="material-symbols-outlined">
                  {openPaths.includes(item.fullPath) ? 
                    "folder_open" 
                  : (isHotfolder(item) ? 
                    "drive_folder_upload"
                  : "folder")}
                </span>
              </TreeItemIcon> 
                  
              {isHotfolder(item) ? 
                <FileUpload
                  filesPaths={filesPaths}
                  fullPath={item.fullPath}
                  refreshList={refreshList}
                  openPaths={openPaths}
                  setOpenPaths={setOpenPaths}
                  retryFolder={retryFolder}
                  purgeFolder={purgeFolder}
                >
                  <TreeItemName>
                    {item.name}
                  </TreeItemName>
                </FileUpload>
              :
                <TreeItemName>
                  {item.name}
                </TreeItemName>
              }

              {item.LastModified ? 
                <>
                  <TreeItemDate>
                    {isSameDate(item.LastModified, new Date(), "d") ?
                      <OzTag size="small">TODAY</OzTag>
                    :
                      getFormattedDate(item.LastModified)
                    }
                  </TreeItemDate>
                  <TreeItemTime>
                    {getFormattedTime(item.LastModified)}
                  </TreeItemTime>
                </>
              : null }
              {/* {isOutput(item) || isHotfolderSubfolder(item) ? 
                <TreeItemRemove onClick={(e) => {deleteFolder(item.fullPath+"/")}}><span className="material-symbols-outlined">delete</span></TreeItemRemove>
              : 
                <TreeItemRemove><span className="material-symbols-outlined">delete</span></TreeItemRemove>
              } */}
                
            </TreeItemRow>

          </TreeItem>
          {(openPaths.includes(item.fullPath)) && item.children.map((subitem, i) => 
            <Tree 
              hfList={hfList}
              key={i}
              previewFile={previewFile}
              deleteFile={deleteFile} 
              retryFile={retryFile}
              deleteFolder={deleteFolder}
              retryFolder={retryFolder}
              purgeFolder={purgeFolder}
              getFiles={getFiles}
              mode={mode}
              setActiveNode={setActiveNode}
              activeNode={activeNode}
              filesPaths={filesPaths}
              item={subitem}
              level={level+1}
              openPaths={openPaths}
              setOpenPaths={setOpenPaths}
              selected={selected}
              setSelected={setSelected}
              refreshList={refreshList}
              logsList={logsList}
              showTemp={showTemp}
            />
          )}
          <TreeFiles 
            item={item}
            getFiles={getFiles}
            previewFile={previewFile}
            deleteFile={deleteFile} 
            retryFile={retryFile}
            filesPaths={filesPaths}
            openPaths={openPaths}
            level={level}
            selected={selected}
            setSelected={setSelected}
            showTemp={showTemp}
            logsList={logsList}
          />        
        </>           
      </TreeWrapper>
    : null
  )
}

export default Tree;