import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import PromptedButton from '../components/PromptedButton';

import {CHETWODEBLUE, OzBreadcrumbs, OzCard, OzButton, OzTag} from '@ozwol/webui';

const Wrapper = styled.div`
	position: sticky;
	${props => props.isSticky ? `
		top: -26px;
	` : null};
	margin-bottom: 20px;
	width: 100%;
	z-index: 999;

	& > *{
		padding: 12px 30px;	
		${props => props.isSticky ? `
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		` : null};
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
const Breadcrumbs = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	color: ${CHETWODEBLUE};
`;
const Icon = styled.div`
	& > *{
		display: block;
	}
`;
const Anchors = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
`;
const AnchorsIcons = styled.span`
	font-size: 20px;
	line-height: 24px;
`;
const ProjectUuid = styled.div`
	font-size: 10px;
	font-weight: 600;
`;
const RightContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	
`;



const PageHeader = ({
	breadcrumbsIcon,
	breadcrumbsText,
	projectUuid = null,
	back,
	anchors=[],
	buttons=[],
	rightContent=null,
	refScrollerPage
}) => {  
  const [isSticky, setSticky] = useState(null);

	const handleSticky = useCallback(() => {
    const scrollTop = refScrollerPage.current.scrollTop;
    setSticky(scrollTop >= 25);
  }, [refScrollerPage]);

	useEffect(() => {
		if(refScrollerPage && refScrollerPage.current){
			let ref = refScrollerPage.current;
			ref.addEventListener('scroll', handleSticky, false);
			return () => {
				ref.removeEventListener('scroll', handleSticky);
			};
		}
	}, [refScrollerPage, handleSticky]);

  return (
    <Wrapper isSticky={isSticky}>
      <OzCard variant={isSticky ? "default" : "lilac"}>
				<Container>
					<Breadcrumbs>
						{back ? 
							<Link to={back}>
								<OzButton
									variant="push-black" 
									size="small" 
									noMinWidth={true}
								><AnchorsIcons className="material-symbols-outlined">chevron_left</AnchorsIcons></OzButton>
							</Link>
						: null}
						<Icon>
							<span className="material-symbols-outlined">{breadcrumbsIcon}</span>
						</Icon>
						<OzBreadcrumbs values={breadcrumbsText.map(text => ({name: text}))} />
					</Breadcrumbs>
					<Anchors>
						{buttons.map(anchor => 
							anchor ? 
								(anchor.prompt ?
									<PromptedButton 
										key={anchor.icon}
										buttonVariant="push-bittersweet" 
										buttonSize={"default"} 
										buttonText={<><AnchorsIcons className="material-symbols-outlined">{anchor.icon}</AnchorsIcons> {anchor.text}</>} 
										prompt={anchor.prompt}
										disabled={anchor.disabled}
										onConfirm={anchor.onClick} 
									/>  
								:
									<OzButton 
										key={anchor.icon}
										variant="push-black" 
										size="default" 
										disabled={anchor.disabled}
										onClick={anchor.onClick}
									>
										<AnchorsIcons className="material-symbols-outlined">{anchor.icon}</AnchorsIcons> {anchor.text}
									</OzButton>
								)
							: null
						)}
						{isSticky ? 
							<>
								{anchors.map(anchor => 
									anchor ? 
										<OzButton 
											key={anchor.icon}
											variant="push-black" 
											size="default" 
											noMinWidth={false}
											onClick={() => {
												if(anchor.callback){
													anchor.callback();
												}
												setTimeout( () => {
													anchor.ref.current.scrollIntoView({
														behavior: "smooth"
													});
												}, 50);
											}}
										>
											<AnchorsIcons className="material-symbols-outlined">{anchor.icon}</AnchorsIcons> {anchor.text}
										</OzButton>
									: null
								)}
								&nbsp;&nbsp;&nbsp;
								{projectUuid ? 
									<OzTag variant="chetwodeblue" size="medium">
										Project: {projectUuid}
									</OzTag>
								: null}
							</>
						: projectUuid ? 
							<ProjectUuid>Project: {projectUuid}</ProjectUuid>
						: null }
					</Anchors>
					{rightContent ? 
						<RightContent>
							{rightContent}
						</RightContent>
					: null}
				</Container>
			</OzCard>
    </Wrapper>
  )
}

export default PageHeader;
